<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Sign Up" icon=""/>

    <div class="signupContainer">
        <div class="row">

            <div class="col-12 col-lg-8">
                <div class="signupBox border shadow-sm">
                    
                    <div v-if="duplicateStatus === null" class="row p-0 m-0">
                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email">
                            <input v-model="language" class="form-control" placeholder="Language" hidden>
                            <input v-model="currency" class="form-control" placeholder="Currency" hidden>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="firstName" class="form-control" placeholder="First name">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="lastName" class="form-control" placeholder="Last name">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <select v-model="title" class="form-select">
                                <option value="null">Select title</option>
                                <option :value="t.value" v-for="t in titleOpt" :key="t.value">{{ t.label }}</option>
                            </select>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="displayName" class="form-control" placeholder="Display name">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <select v-model="gender" class="form-select">
                                <option value="null">Select gender</option>
                                <option :value="g.value" v-for="g in genderOpt" :key="g.value">{{g.label}}</option>
                            </select>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <select v-model="gmt" class="form-select">
                                <option value="null">Select GMT</option>
                                <option :value="g.value" v-for="g in gmtOpt" :key="g.value">{{g.label}}</option>
                            </select>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <select v-model="userPackage" class="form-select">
                                <option value="null">Select Package</option>
                                <option :value="p.value" v-for="p in packageOpt" :key="p.value">{{p.label}}</option>
                            </select>
                        </div>

                        <div class="col-12 col-lg-6 pb-3 d-none d-md-block"></div>


                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImg" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptcha" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captcha" class="form-control" placeholder="Captcha">
                        </div>

                        <div class="col-12 col-lg-8 pb-1">An email will be sent to you for activation purpose, and it must be activated within 24 hours</div>

                        <div class="col-12 col-lg-4 text-end">
                            <button type="button" class="btn btn-secondary" @click="signup">Sign Up</button>
                        </div>

                    </div>

                    <div v-if="duplicateStatus !== null && !showResend" class="row p-0 m-0">
                        <div v-if="duplicateStatus === 'PENDING' || duplicateStatus === 'RACTIVE'" class="col-12">
                            <div class="mb-3">Your account is registered but not activated, please activate your account.</div>
                            <div>If you do not receive activation email, please click <span class="isLink" @click="showResend = true"><u>here</u></span> to send again, wait 5 minutes and check your junk/spam folder.</div>
                        </div>

                        <div v-if="duplicateStatus === 'ACTIVE'" class="col-12">
                            <div>Your account is registered, please click <span class="isLink"  @click="$router.push(urlForgot)"><u>here</u></span> to reset your password if you have forgot your password</div>
                        </div>

                        <div v-if="duplicateStatus === 'EXPIRED'" class="col-12">
                            <div class="mb-3">Your account is registered but not activated, your account will be deleted on {{func.convDateTimeFormat(delDatetime, 'datetime')}}.</div>
                            <!-- <div>Please click <span class="isLink" @click="resendActivation"><u>here</u></span> to send activation email again, wait 5 minutes and check your junk/spam folder.</div> -->
                            <div>Please click <span class="isLink" @click="showResend = true"><u>here</u></span> to send activation email again, wait 5 minutes and check your junk/spam folder.</div>
                        </div>
                    </div>

                    <div v-if="duplicateStatus !== null && showResend" class="row p-0 m-0">
                        <div class="col-12 pb-3">
                            <div class="col-12 pb-2">Fill in detail below to resend activation link</div>
                        </div>
                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captchaResend" class="form-control" placeholder="Captcha">
                            <div class="text-end"><button type="button" class="btn btn-secondary mt-4" @click="resendActivation">Resend</button></div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="col-12 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div class="border shadow-sm greyBox">
                            <div>Existing user?</div>
                            <router-link :to="{ name: 'Login', query: route.query}">
                                <div class="greyLink"><u>Login</u></div>    
                            </router-link>
                        </div>

                        <div  class="border shadow-sm greyBox">
                            <div>Still undecided what to do?</div>
                            <div class="isLink"><u>View our demo now</u></div>
                        </div>

                        <div  class="border shadow-sm greyBox">
                            <div>Need more help?</div>
                            <div>Email to <u>support@signon.my</u></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import auth from '@/functions/auth'

export default {
    name: 'SignUp',
    components: { TopNavigation, Alert, Popper },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId } = useStore()

        const email = ref(null)
        const firstName = ref(null)
        const lastName = ref(null)
        const displayName = ref(null)
        const title = ref('Mr')
        const gender = ref('M')
        const userPackage = ref(null)
        const gmt = ref('+8:00')
        const language = ref('EN')
        const currency = ref('MYR')
        const captchaImg = ref(null)
        const refId = ref(null)
        const captcha = ref(null)

        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)

        const urlLogin = ref('/auth/login')
        const urlForgot = ref('/auth/forgotpassword')
        const duplicateStatus = ref(null) // ACTIVE/PENDING/EXPIRED/RACTIVE
        // const duplicateStatus = ref('PENDING')
        const showResend = ref(false)
        const delDatetime = ref(null)

        const gmtOpt = ref(null)
        const titleOpt = ref(null)
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const packageOpt = [
            { label: 'Free', value: 'Free'},
            { label: 'Personal', value: 'Personal'},
            { label: 'Team', value: 'Team'}
        ]

        const closeAlert = (index) => {
            // alert.value = null
            alert.value.splice(index, 1)
        }

        watch([title], () => {
            if (title.value === 'Mrs' || title.value === 'Madam' || title.value === 'Miss') {
                gender.value = 'F'
            }

            if (title.value === 'Mr') {
                gender.value = 'M'
            }

        })

        const signup = () => {
            duplicateStatus.value = null

            if (email.value === null || email === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Email is required.'
                    })

            } else if (displayName.value === null || displayName.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Display name is required.'
                    })

            } else if (userPackage.value === null || userPackage.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Package is required.'
                    })

            } else if (captcha.value === null || captcha.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Captcha is required.'
                    })

            } else {

                const p = {
                    username: email.value,
                    refId: refId.value,
                    captcha: captcha.value, 
                    lastName: lastName.value,
                    firstName: firstName.value,
                    displayName: displayName.value,
                    gender: gender.value,
                    userTitle: title.value,
                    package: userPackage.value,
                    gmt: gmt.value,
                    language: language.value,
                    currency: currency.value,
                    frontendUrl: 'https://signon.my/auth/activate',
                    autoLogin: 1
                }

                console.info('signup REQ', JSON.stringify(p))
                
                axios.post('/auth/signup', p)
                    .then((res) => {
                        console.info('signup RES', JSON.stringify(res.data.data))

                        if (res.data.status === 1) {
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Signup successfully. An activation email has sent to you, please click the link to activate it."
                            })

                            // AutoLogin, even not activate account yet
                            localStorage.setItem('userId', res.data.data.userId)
                            localStorage.setItem('sessionId', res.data.data.sessionId)
                            localStorage.setItem('displayName', res.data.data.displayName)
                            localStorage.setItem('avatar', '')
                            localStorage.setItem('role', res.data.data.role)
                            localStorage.setItem('userIsVerified', false) // default
                            localStorage.setItem('userStatus', 'PENDING') // default
                            localStorage.setItem('doc', {})

                            setTimeout(() => {
                                router.push('/uploadDoc')
                                .then(() => {
                                    // refresh the page so that can capture sessionId in store
                                    router.go() 
                                })
                            , 2000})
                        }
                    })
                    .catch((error) => {
                        console.log('signup | ERR', JSON.stringify(error))

                        if (error.status === 'captcha_not_match' || error.status === 'refId_not_found' || error.status === 'ip_not_match') {

                            getCaptcha()
                            captcha.value = ''

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Captcha not matched. Please try again. (' + error.code + ')'
                            })

                        } else if (error.status === 'refId_expired') {

                            getCaptcha()
                            captcha.value = ''

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Captcha is expired. Please try again. (' + error.code + ')'
                            })

                        } else if (error.status === 'duplicate_user') {

                            duplicateStatus.value = error.data.data.status
                            delDatetime.value = error.data.data.dateDelete
                            getCaptchaResend()

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'This user is already registered, please report to support@signon.my if you believe someone has stolen your identify, with evidences. (' + error.code + ')'
                            })

                        } else {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.convError(error)
                            })
                        }
                    })
            }
        }

        const getGmt = () => {
            // console.info('gmt', new Date())
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1) {
                        gmtOpt.value = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('getGmt | ERR', JSON.stringify(error))
                })
        }

        const getCaptcha = () => {
            axios.get('/reference/captcha/SIGNUP')
                .then((res) => {
                    if (res.data.status === 1) {
                        captchaImg.value = res.data.data.captcha.image
                        refId.value = res.data.data.refId
                    }
                })
                .catch((error) => {
                    console.log('getCaptcha | ERR', JSON.stringify(error))
                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {
                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId
                    }
                })
                .catch((error) => {
                    console.log('getCaptcha | ERR', JSON.stringify(error))
                })
        }

        const resendActivation = () => {
            const p = {
                username: email.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: 'https://signon.my/auth/activate'
            } 

            console.info('resendActivation', JSON.stringify(p))
            axios.post('/auth/signup/resend')
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Activation email is sent, please wait 5 minutes and check your junk/spam folder."
                        })
                    }
                })
                .catch((error) => {
                    console.log('resendActivation | ERR', JSON.stringify(error))
                })
        }

        onMounted(() => {
            auth.clearStorage()

            getCaptcha()
            getGmt()

            // Title list
            axios.get('/reference/title')
            .then((res) => {
                if (res.data.status === 1) {
                    titleOpt.value = res.data.data
                }
            })
            .catch((error) => {

                console.log('delDoc | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        })

        return { 
            alert, route, router, closeAlert, 
            email, firstName, lastName, displayName, title, gender, gmt, language, currency, userPackage, 
            genderOpt, titleOpt, urlLogin, urlForgot, signup, captchaImg, captcha, refId, gmtOpt, packageOpt, 
            getCaptcha, duplicateStatus, resendActivation, showResend, func, delDatetime,
            getCaptchaResend, captchaImgResend, refIdResend, captchaResend
        }
    }
}
</script>

<style>

.signupContainer {
    width: 59%; 
    margin: 105px auto 20px auto;
}

@media (max-width: 575.98px) {
    .signupContainer {
        width: 95%; 
        margin: 105px auto 20px auto;
    }
}

.signupBox {
    border-radius: 10px;
    margin: 0px 2px 40px 2px;
    padding: 25px 18px;
}

.greyBox {
    border-radius: 10px;
    padding: 25px; 
    margin-bottom: 30px;
    background-color: var(--auth-box-bg);
}

</style>